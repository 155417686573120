import Container from 'AtomicComponents/Container/Container'
import Heading from 'AtomicComponents/Heading/Heading'
import PersonalInfo from './PersonalInfo'
import Skills from './Skills'
import ExperienceAndEducation from './ExperienceAndEducation'

export default function About() {
  return (
    <>
      <div className='pf-animate-fadeIn pf-w-full pf-h-full max-xl:pf-overflow-x-hidden'>
          <Container.Container>
              <div className="pf-relative pf-top-20 md:pf-top-24 pf-z-0">

                  <Heading.Heading subHeading="resume" heading={["About", "me"]} className="pf-pb-[70px]"/>

                  {/* INFO */}
                  <PersonalInfo />
                  <hr className=' pf-bg-secondary-light pf-w-1/3 pf-my-[50px] pf-mx-auto' />
                  

                  {/* SKILL */}
                  <Skills />
                  <hr className=' pf-bg-secondary-light pf-w-1/3 pf-my-[50px] pf-mx-auto' />

                  {/* EXPERIENCE & EDUCATION */}
                  <ExperienceAndEducation />
                  <hr className=' pf-bg-secondary-light pf-w-1/3 pf-my-[50px] pf-mx-auto' />

              </div>

          </Container.Container>
      </div>

      {/* top down animate */}
      <div className='topDownPageRenderTransisition'></div>
    </>
  )
}
