

import { Route, Routes as RouteWrapper } from "react-router-dom";
import Home from "Components/Home/Home";
import About from "Components/About/About";
import Portfolio from "Components/Portfolio/Portfolio";
import Contact from "Components/Contact/Contact";

function Routes() {
  return (
    <RouteWrapper>
        <Route exact path="/" element={<Home/>}/>
        <Route exact path="/about-me" element={<About/>}/>
        <Route exact path="/works" element={<Portfolio/>}/>
        <Route exact path="/contact" element={<Contact/>}/>
        <Route path="*" element={<Home/>}/>
    </RouteWrapper>
  )
}

export default Routes;