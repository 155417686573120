import React from 'react'

const Navbar = (props)=> {
  return (
    <div className={props.className}>
        {props.children}
    </div>
  )
}

const Icon = (props) => {
    return props.icon && <i className={props.icon} />
}

const Text = (props) => {
    return (
        <span className={props.className}>
            {props.text}
        </span>
    )
}

const Item = (props) => {
    const {className, children, onClick=()=>null} = props;
    return (
        <div className={className} onClick={onClick}>
            {children}
        </div>
    )
}

const Nav = {
    'Navbar': Navbar,
    'Icon': Icon,
    'Item': Item,
    'Text': Text
}

export default Nav;