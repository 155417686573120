import Modal from 'AtomicComponents/Modal/Modal';
import useMouseCoordinates from 'hooks/useMouseCoordinates';
import {useMemo, useRef, useState} from 'react'
import { DIRECTIONS, animationOptions } from './work.config';

function generateSixDigitRandomNumber() {
    // Generate a random decimal between 0 and 999999
    const randomDecimal = Math.floor(Math.random() * 900000) + 100000;
  
    // Convert the decimal to a string with leading zeros
    const randomNumberString = randomDecimal.toString().padStart(6, '0');
  
    // Return the 6-digit string as an integer
    return parseInt(randomNumberString);
}
  


export default function WorkCard(props) {

    const randomNumber =  useMemo(generateSixDigitRandomNumber,[]);
    const {work} = props;
    const ref = useRef(null);
    const animateBoxRef = useRef(null);
    const mouseCoordinates = useMouseCoordinates({ref});
    const [isOverlayVisible, setIsOverlayVisible] = useState(false);

    const [positioningStyle, setPositioningStyle] = useState("")

    const [showMoreInfo, setShowMoreInfo] = useState(false);

    const handleWorkSelection = (e,showInfo=false)=>{
        e.stopPropagation();
        setShowMoreInfo(showInfo);
    }

    /**
     * Clear if overlay left but mouse is not on this ref
     */
    const handleClearOverlay = ()=>{
        // current mouse premises compared to ref
        const {top, left, width} = ref.current.getBoundingClientRect();
        // after leaving client 
        const clientX = mouseCoordinates.x, clientY = mouseCoordinates.y;

        // if on the right
        let dir = DIRECTIONS.LEFT
        if(clientX>(left+width)){
            dir = DIRECTIONS.RIGHT;
        }else if(clientX<left){
            dir = DIRECTIONS.LEFT;
        }else if(clientY<top){
            dir = DIRECTIONS.TOP;
        }else{
            dir = DIRECTIONS.BOTTOM;
        }

        handleMouseLeave({clientX, clientY}, true, dir)
    }

    const handleMouseEnter = (event)=>{
        if(event.stopPropagation){
            event.stopPropagation();
        }
        const {top, left, width, height} = ref.current.getBoundingClientRect();
        const centerX = left+(width/2), centerY = top+(height/2);
        // after leaving client 
        const clientX = event.clientX, clientY = event.clientY;

        // entry direction
        let eTop=false, eBottom=false, eLeft=false, eRight=false;

        if(clientX>centerX){
            eRight = true;
        }else{
            eLeft = true;
        }

        if(clientY>centerY){
            eBottom = true;
        }else{
            eTop = true;
        }

        const dir  = isIntersecting({direction:{eTop, eLeft, eRight, eBottom}, width, height, top, left, centerX, centerY })

        let animate = "";
        if(dir===DIRECTIONS.TOP){
            animate = "animateTToB";
        }else if(dir===DIRECTIONS.LEFT){
            animate = "animateLToR";
        }else if(dir===DIRECTIONS.RIGHT){
            animate = "animateRToL";
        }else if(dir===DIRECTIONS.BOTTOM){
            animate = "animateBToT";
        }

        if(animate){
            setIsOverlayVisible(true);
            setPositioningStyle(animate)
        }
    }

    const handleMouseLeave = (event, forced=false, forcedDirection=DIRECTIONS.LEFT)=>{
        if(event.stopPropagation){
            event.stopPropagation();
        }
        const {top, left, width, height} = ref.current.getBoundingClientRect();
        const centerX = left+(width/2), centerY = top+(height/2);
        // after leaving client 
        const clientX = event.clientX, clientY = event.clientY;

        // entry direction
        let eTop=false, eBottom=false, eLeft=false, eRight=false;

        if(clientX>centerX){
            eRight = true;
        }else{
            eLeft = true;
        }

        if(clientY>centerY){
            eBottom = true;
        }else{
            eTop = true;
        }

        const dir  = forced ? forcedDirection : isIntersecting({direction:{eTop, eLeft, eRight, eBottom}, width, height, top, left, centerX, centerY, clientX, clientY, isExit:true })

        let frames = [];
        if(dir===DIRECTIONS.TOP){
            frames = [ 
                {top: animateBoxRef.current.offsetTop +'px',left: animateBoxRef.current.offsetLeft + 'px'},
                {top: '-100%',left: '0%'}
            ];
        }else if(dir===DIRECTIONS.LEFT){
            frames = [ 
                {top: animateBoxRef.current.offsetTop +'px',left: animateBoxRef.current.offsetLeft + 'px'},
                {top: '0%',left: '-100%'}
            ];       
        }else if(dir===DIRECTIONS.RIGHT){
            frames = [ 
                {top: animateBoxRef.current.offsetTop +'px',left: animateBoxRef.current.offsetLeft + 'px'},
                {top: '0%',left: '100%'}
            ];        
        }else if(dir===DIRECTIONS.BOTTOM){
            frames = [ 
                {top: animateBoxRef.current.offsetTop +'px',left: animateBoxRef.current.offsetLeft + 'px'},
                {top: '100%',left: '0%'}
            ];      
        }

        if(frames.length){
            animateBoxRef.current.animate(frames,animationOptions).onfinish = ()=>{
                animateBoxRef.current.classList.forEach((lists)=>{
                    if(lists.startsWith('animate')){
                        animateBoxRef.current.classList.remove(lists);
                    }
                })
                setPositioningStyle(`pf-top-[${frames[1].top}] pf-left-[${frames[1].left}]`)
            }
            setIsOverlayVisible(false);
        }
    }

    const isIntersecting = ({direction, width, height, top, left, centerX, centerY, clientX, clientY, isExit=false})=>{

        const cursor = isExit ? 
        {x1:clientX, y1:clientY, x2:mouseCoordinates.x, y2:mouseCoordinates.y}
        :
        {x1:mouseCoordinates.x, y1:mouseCoordinates.y, x2:centerX, y2:centerY}


        const edges = {
            [DIRECTIONS.TOP]:{x1:left, y1:top, x2:left+width, y2:top},
            [DIRECTIONS.LEFT]:{x1:left, y1:top, x2:left, y2:top+height},
            [DIRECTIONS.BOTTOM]:{x1:left, y1:top+height, x2:left+width, y2:top+height},
            [DIRECTIONS.RIGHT]:{x1:left+width, y1:top, x2:left+width, y2:top+height}
        }

        let dir = null;
        Object.keys(edges).forEach((type)=>{
            const edge = edges[type];
            const edgeSlope = (edge.y2 - edge.y1) / (edge.x2 - edge.x1); // Slope of static line (0, Infinity, or -Infinity)
            // Check if edge is a vertical line (edgeSlope is Infinity or -Infinity)
            if (edgeSlope === Infinity || edgeSlope === -Infinity) {
    
                if (edge.x1 >= Math.min(cursor.x1, cursor.x2) && edge.x1 <= Math.max(cursor.x1, cursor.x2)) {
                    // const yIntersect = cursorSlope * (edge.x1 - cursor.x1) + cursor.y1;
                    if(direction.eLeft){
                        dir = DIRECTIONS.LEFT;
                    }else{
                        dir = DIRECTIONS.RIGHT;
                    }
    
                }
            }
        
            // Check if edge is a horizontal line (edgeSlope is 0)
            else if (edgeSlope === 0) {
                // Check if cursor intersects with the horizontal line (edge)
                if (edge.y1 >= Math.min(cursor.y1, cursor.y2) && edge.y1 <= Math.max(cursor.y1, cursor.y2)) {
                    // const xIntersect = (edge.y1 - cursor.y1) / cursorSlope + cursor.x1;
                    if(direction.eTop){
                        dir = DIRECTIONS.TOP;
                    }else{
                        dir = DIRECTIONS.BOTTOM;
                    }
                }
            }
        })

        return dir;

    }

    // edge case
    !mouseCoordinates.isInsideRef && isOverlayVisible && handleClearOverlay(); 

    return (
        <>
            <div 
                className="pf-w-full pf-h-full pf-relative pf-overflow-hidden"
                onClick={(e)=>handleWorkSelection(e,true)}
                onMouseEnter={handleMouseEnter} 
                onMouseLeave={handleMouseLeave}
                ref={ref}
                id={randomNumber}
            >
                <img src={work.src} alt={work.name} className="pf-h-56 pf-w-full pf-object-cover pf-rounded-md"/>
                <div ref={animateBoxRef} className={['workTextOverlay max-xl:!pf-hidden', positioningStyle].join(' ')}>{work.name}</div>
            </div>

    
            {/* More Info */}
            {
                showMoreInfo && 
                <Modal handleClose={(e)=>handleWorkSelection(e, false)}>
                    <div className="pf-relative pf-py-0 pf-ps-[60px] pf-pe-5 pf-box-border pf-mb-[30px] after:pf-content-[''] after:pf-border-[1px] after:pf-border-solid after:pf-border-secondary-dark after:pf-border-spacing-60 after:pf-h-full after:pf-absolute after:pf-top-[10px] after:pf-left-[1.2rem] after:pf-opacity-20" key={`works_active_modal`}>
                        {
                            work.icon && <i className={[work.icon, "pf-absolute pf-top-0 pf-left-0 pf-z-[1] pf-border pf-border-solid pf-border-primary pf-rounded-[50%] pf-p-1 pf-bg-primary pf-text-white pf-inline-flex pf-justify-center pf-items-center pf-w-10 pf-h-10 pf-text-xl"].join(' ')} />
                        }
                        <span className='pill'>{work.domain}</span>
                        <p className=' pf-text-lg pf-my-3 pf-uppercase'>
                            <span className='pf-text-secondary-dark pf-font-bold'>{work.name} &nbsp;-&nbsp; </span>
                            <span className='pf-text-secondary-dark pf-opacity-80 pf-font-semibold pf-text-xl'>{work.organization}</span>
                            {
                                work.link &&  <a href={work.link} target='_blank' rel="noreferrer"><i className="ph-bold ph-paper-plane-tilt pf-ml-3"></i></a>
                            }
                        </p>
                        {
                            <ul className={["pf-transition-all pf-duration-500 pf-ease-in-out"].join(" ")}>
                                <li className="pf-py-0 max-xl:pf-ps-0 max-xl:pf-pe-0 xl:pf-ps-8 xl:pf-pe-4 pf-my-4">
                                        <p className='beforeDash' dangerouslySetInnerHTML={{__html: work.description.text}} />
                                        <p className='pf-break-words pf-mt-2'>
                                            {
                                                work.description.tech.map((_tech, _tdx)=>{
                                                    return <span key={`work_modal_expanded_${_tdx}`} className="pill pf-m-2 !pf-text-sm pf-inline-flex">{_tech}</span>
                                                
                                                })
                                            }
                                        </p>
                                        {
                                            Array.isArray(work.images) && (work.images.length>0) && <p className='pf-w-auto pf-overflow-y-hidden pf-overflow-x-scroll pf-flex pf-grid-cols-12 pf-gap-x-4 pf-gap-y-2 pf-py-2 pf-px-3'>
                                                {
                                                    work.images.map((images, _wIdx)=>{
                                                        return (
                                                            <img src={images} alt="" key={`work_images_${_wIdx}`} className='pf-h-96 max-xl:pf-h-56 pf-object-contain pf-col-span-4 pf-border-secondary-dark pf-border-solid pf-border-2 pf-rounded-lg' />
                                                        )
                                                    })
                                                }
                                            </p>
                                        }
                                    </li>
                            </ul>
                        }
                    </div>
                </Modal>
            }
        </>
    )
}
