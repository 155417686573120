import React from 'react'

const Head = (props)=> {
    const {subHeading, heading, className : propClass=""} = props;
    return (
        <div className={['pf-flex-col pf-inline-flex pf-items-center pf-justify-center pf-uppercase pf-w-full', propClass].join(' ')}>
            <h4 className='max-xl:pf-text-5xl pf-text-7xl pf-font-extrabold pf-text-secondary-dark pf-z-10 pf-text-center'>{heading[0]} <span className='pf-text-primary'>{heading[1]}</span></h4>
            <h1 className='max-xl:pf-text-7xl pf-text-9xl pf-absolute pf-text-secondary-light pf-leading-3 pf-font-bold pf-z-0 pf-opacity-40 pf-tracking-wider'>{subHeading}</h1>
        </div>
    )
}

const Heading = {
    'Heading': Head
}

export default Heading;