import Button from 'AtomicComponents/Button/Button'
import profilePic from 'assets/image/ProfileCompressed.png'
import { memo } from 'react'
import { homeConfig } from 'Components/Home/home.config'
import {useNavigate} from 'react-router-dom'

export default memo(function XLDevicesHome() {
    const navigate = useNavigate()
    const handleRedirection = () => {
        navigate('/about-me')
    }
    return (
        <div className='pf-animate-fadeIn flex pf-w-screen pf-h-screen pf-grid pf-grid-cols-12 pf-gap-4'>
            {/* left */}
            <div className='pf-col-span-5 pf-relative pf-overflow-hidden'>
                <div className="pf-fixed pf-bg-primary pf-left-[-83%] pf-h-[200%] pf-w-full pf-top-[-50%] slantDesign"></div>
                <img className='profileImg' src={profilePic} alt="profile" />
            </div>

            {/* Right */}
            <div className='pf-col-span-7'>
                <div className='pf-w-4/5 pf-h-full pf-flex pf-flex-col pf-justify-center pf-items-baseline pf-p-8 pf-break-words'>
                    <div className='preceedingDash pf-text-5xl pf-font-bold'>
                        <h1 className="pf-uppercase pf-ml-8 pf-text-primary">
                            {homeConfig.name}
                            <span className="pf-uppercase pf-block pf-text-secondary-dark pf-mt-2 pf-leading-20">{homeConfig.subHeadings[0]} <br/>{homeConfig.subHeadings[1]}</span>
                        </h1>
                    </div>


                    <p className='pf-my-8 pf-text-base pf-leading-9 pf-text-secondary-dark '>
                    {homeConfig.description}
                    </p>


                    <Button.Button onClick={handleRedirection}>
                        <Button.Item className="capsuleButton">
                            <Button.Text className="pf-col-span-9 pf-uppercase pf-inline-flex pf-justify-center pf-items-center pf-font-semibold" text={homeConfig.cta} />
                            <Button.Item className="pf-col-span-3 pf-inline-block pf-bg-primary pf-text-white pf-text-2xl pf-border-solid pf-p-4 pf-rounded-[50%] pf-leading-4">
                                <Button.Icon icon="ph-fill ph-arrow-right"/>
                            </Button.Item>
                        </Button.Item>
                    </Button.Button>
                </div>
            </div>
        </div>
    )
})
