import React from 'react'
import Nav from 'AtomicComponents/Navbar/Navbar';
import {Fragment, memo} from 'react'

export default memo(function XLDevicesNavbar(props) {
    const {items, handlePageChange, currentPath} = props;
    return (
        <Nav.Navbar className="pf-fixed pf-h-full pf-w-fit pf-right-0 pf-z-[9999]">
            <Nav.Item className=" max-xl:pf-hidden xl:pf-visible pf-absolute pf-bottom-0 pf-h-1/2 pf-right-0 pf-top-1/2 pf-w-20 pf-translate-y-[-50%] pf-translate-x-[-25%] xl:pf-flex pf-flex-col pf-justify-evenly pf-items-center pf-max-h-[400px]">
            {
                items.map((_item, index)=>{
                    const isActive = currentPath===_item.redirect;
                    const activeClassName = isActive ? 'pf-bg-primary pf-text-white' : 'pf-bg-secondary pf-text-secondary-dark pf-cursor-pointer hover:pf-bg-primary hover:pf-text-white';
                    return <Fragment key={`nav_items_${index}`}>
                        <div 
                            onClick={()=>handlePageChange(_item.redirect,isActive)} 
                            className='pf-w-fit pf-h-fit'
                        >
                            <Nav.Item className={["pf-text-2xl pf-p-4 pf-rounded-[50%] pf-leading-4 pf-z-10 pf-relative", activeClassName].join(' ')}>
                                <Nav.Icon icon={_item.icon} />
                                <Nav.Text className="navText" text={_item.text} />
                            </Nav.Item>
                        </div>
                    </Fragment>
                })
            }
            </Nav.Item>
    </Nav.Navbar>
    )
})
