const items = [
  {
    redirect: "/",
    icon: "ph-fill ph-house-line",
    text: "home",
  },
  {
    redirect: "/about-me",
    icon: "ph-fill ph-user",
    text: "about",
  },
  {
    redirect: "/works",
    icon: "ph-fill ph-briefcase",
    text: "portfolio",
  },
  {
    redirect: "/contact",
    icon: "ph-fill ph-envelope",
    text: "contact",
  },
];

export { items };
