const info = {
    'heading': "Go for it!",
    'subHeading': "Feel free to get in touch with me. I am always open to discussing new projects, creative ideas or opportunities to be part of your visions.",
    'formalSocialMethods':[
        {
            'key':'Mail me',
            'value':'riteshdabral1@gmail.com',
            'icon': 'ph-fill ph-envelope-open'
        },
        {
            'key':'call me',
            'value':'+91-9911536608, +91-9315529523',
            'icon': 'ph-fill ph-phone'
        }
    ],
    'inFormalSocialMethods':[
        // {
        //     'key':'facebook',
        //     'value':'facebook',
        //     'icon': 'ph-fill ph-facebook-logo'
        // },
        // {
        //     'key':'instagram',
        //     'value':'instagram',
        //     'icon': 'ph-fill ph-instagram-logo'
        // },
        {
            'key':'linkedin',
            'value':'https://www.linkedin.com/in/ritesh-dabral-51295713a/',
            'icon': 'ph-fill ph-linkedin-logo'
        },
        {
            'key':'github',
            'value':'https://github.com/Ritesh-Dabral',
            'icon': 'ph-fill ph-github-logo'
        },
        {
            'key':'gitlab',
            'value':'https://gitlab.com/riteshdabral',
            'icon': 'ph-fill ph-gitlab-logo-simple'
        }
    ]
}

const inputKeys = {
    name: 'name',
    email: 'email',
    subject: 'subject',
    message: 'message'
}

const CONTACT_SUBMITTED_COOKIE = "contactSubmitted";
const CONTACT_SUBMITTED_TIME_COOKIE = "contactSubmittedTime";
const BUTTON_NOT_READY_TILL_HRS = 3

export {
    info,
    inputKeys,
    CONTACT_SUBMITTED_COOKIE,
    CONTACT_SUBMITTED_TIME_COOKIE,
    BUTTON_NOT_READY_TILL_HRS
}