import Container from 'AtomicComponents/Container/Container'
import Heading from 'AtomicComponents/Heading/Heading'
import Mailer from './Mailer'
import BasicInfo from './BasicInfo'

export default function Contact() {
    return (
        <>
            <div className='pf-animate-fadeIn pf-w-full pf-h-full max-xl:pf-overflow-x-hidden'>
                <Container.Container>
                    <div className="pf-relative pf-top-20 md:pf-top-24 pf-z-0 pf-pb-24">
                        <Heading.Heading subHeading="contact" heading={["get in", "touch"]} className="pf-pb-[70px]"/>

                        <div className=' xl:pf-grid xl:pf-grid-cols-9 xl:pf-gap-4'>

                            <div className='xl:pf-col-span-3 max-xl:pf-mb-16'>
                                <BasicInfo />
                            </div>

                            <div className='xl:pf-col-span-6'>
                                <Mailer />
                            </div>
                        </div>

                    </div>
                </Container.Container>
            </div>

            {/* top down animate */}
            <div className='topDownPageRenderTransisition'></div>
        </>
    )
}
