import {memo} from "react";
import { skills } from "./about.config";

export default memo(function Skills() {
  return (
    <div className="pf-w-full pf-h-max">
      <p className="pf-uppercase pf-col-span-12 pf-text-[26px] pf-font-bold pf-text-secondary-dark pf-text-center pf-pb-6">
        My Skills
      </p>

      {skills.map((_skillSet, index) => {
        return (
          <div
            className="pf-w-full pf-h-auto pf-box-border pf-pb-5"
            key={`skill_set_${index}`}
          >
            <div className="pf-text-lg pf-flex pf-place-items-start">
              <i className={[_skillSet.icon, "pf-w-10 pf-h-10 pf-mx-2 pf-text-white pf-bg-primary pf-inline-flex pf-justify-center pf-items-center pf-rounded-[50%]"].join(" ")} />
              <span className="pf-text-secondary-dark pf-bg-secondary pf-px-3 pf-py-[2px] pf-rounded-[20px] pf-font-medium !pf-text-sm pf-uppercase max-xl:pf-text-center max-xl:pf-px-1">
                {_skillSet.topic}
              </span>
            </div>

            <ul
              className="pf-grid max-xl:pf-grid-cols-2 pf-grid-cols-6 pf-gap-1 pf-px-5"
              key={`skill_details_container_${index}`}
            >
              {_skillSet.details.map((_details, _idx) => {
                return (
                  <li
                    className="pf-h-max pf-box-border pf-px-2 pf-py-1"
                    key={`skillset_details_${_idx}`}
                  >
                    <span className="pf-uppercase pf-font-bold pf-text-sm pf-opacity-60 beforeDash">
                      {_details.skill}
                    </span>
                  </li>
                );
              })}
            </ul>
          </div>
        );
      })}
    </div>
  );
})
