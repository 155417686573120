const Btn = (props)=> {
    const {onClick:propsOnClick = ()=>{}} = props;
    return (
        <button className={props.className} disabled={props.disabled} onClick={propsOnClick}>
            {props.children}
        </button>
    )
}

const Icon = (props) => {
    return props.icon && <i className={props.icon} />
}

const Text = (props) => {
    return (
        <span className={props.className}>
            {props.text}
        </span>
    )
}

const Item = (props) => {
    return (
        <div className={props.className}>
            {props.children}
        </div>
    )
}

const Button = {
    'Button': Btn,
    'Icon': Icon,
    'Item': Item,
    'Text': Text
}

export default Button;