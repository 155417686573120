import {Fragment, memo} from "react";
import { personalInfo, stats, cvDetails } from "./about.config";
import Button from "AtomicComponents/Button/Button";

export default memo(function PersonalInfo() {

  const handleViewCV = (event, link)=>{
    event.stopPropagation();
    window.open(link, '_blank', 'noreferrer');
  }

  return (
    <div className="pf-w-full pf-h-max xl:pf-grid xl:pf-grid-cols-12 xl:pf-gap-3">
      <div className="xl:pf-col-span-6 pf-mb-4">
        <p className="pf-uppercase pf-text-[26px] pf-font-bold pf-text-secondary-dark pf-pb-6">
          Personal Info
        </p>
        <div className="max-md:pf-flex max-md:pf-justify-between max-md:pf-items-center md:pf-grid md:pf-grid-cols-12 md:pf-gap-1">
          {personalInfo.map((_batch, index) => {
            const details = _batch.map((info, _idx) => {
              return (
                <Fragment key={`personal_info_batch_${_idx}`}>
                  <li className="pf-text-base pf-text-secondary-dark pf-pb-5 pf-font-bold">
                    <p>
                      <span className="pf-opacity-80 pf-font-medium">
                        {info.key} &nbsp;:&nbsp;
                      </span>
                      <span className="max-md:pf-block">{info.value}</span>
                    </p>
                  </li>
                </Fragment>
              );
            });
            return (
              <ul
                className="md:pf-col-span-6"
                key={`personal_info_container_${index}`}
              >
                {details}
              </ul>
            );
          })}
        </div>

        <Button.Button onClick={(e)=>handleViewCV(e,cvDetails.link)}>
          <Button.Item className="capsuleButton">
            <Button.Text
              className="pf-col-span-9 pf-uppercase pf-inline-flex pf-justify-center pf-items-center pf-font-semibold"
              text={cvDetails.text}
            />
            <Button.Item className="pf-col-span-3 pf-inline-block pf-bg-primary pf-text-white pf-text-2xl pf-border-solid pf-p-4 pf-rounded-[50%] pf-leading-4">
              <Button.Icon icon="ph-fill ph-arrow-right" />
            </Button.Item>
          </Button.Item>
        </Button.Button>
      </div>

      {/* STATS */}
      <div className="pf-col-span-6 max-xl:pf-mt-5">
        <div className="pf-grid pf-grid-cols-12 pf-gap-5">
          {stats.map((_batch, index) => {
            const details = _batch.map((info, _idx) => {
              return (
                <Fragment key={`stats_batch_${_idx}`}>
                  <li className="pf-text-base pf-text-secondary-dark pf-border pf-border-solid pf-border-secondary-light pf-pt-5 max-xl:pf-ps-2 pf-ps-10 max-xl:pf-pe-2 pf-pe-[30px] pf-pb-[25px] pf-rounded-md pf-h-36 pf-inline-flex pf-flex-col pf-justify-center pf-w-full">
                    <div className=" pf-text-primary pf-opacity-80 pf-font-bold pf-text-5xl">
                      {info.key}
                    </div>
                    <div className="before:pf-content-['-'] before:pf-relative before:pf-text-secondary-dark before:pf-mx-2 before:pf-font-bold before:pf-text-xl">
                      {info.value}
                    </div>
                  </li>
                </Fragment>
              );
            });
            return (
              <ul
                className="max-xl:pf-col-span-12 pf-col-span-6 pf-uppercase pf-grid pf-gap-5"
                key={`stats_container_${index}`}
              >
                {details}
              </ul>
            );
          })}
        </div>
      </div>
    </div>
  );
})
