import { useRef } from 'react';
import {createPortal} from 'react-dom'

export default function Modal(props) {

    const modalRef = useRef(null)
    const {children, handleClose=()=>{}} = props;

    const handleSmoothCloseEvent = (e)=>{

        // remove current animation class
        modalRef.current.classList.forEach((className)=>{
            if(className.includes("animate")){
                modalRef.current.classList.remove(className);
            }
        })

        const frames = [ 
            {left: '0%'},
            {left: '-100%'}
        ];

        const modalAnimation = modalRef.current.animate(
            frames,
            {
                duration: 500,   
                forwards: true,
                easing: 'cubic-bezier(0.77,0.2,0.05,1.0)'
            }
        )
        
        modalAnimation.onfinish = ()=>{
            modalRef.current.style.left = frames[1].left;
            // finally call this
            handleClose(e)
        }

    }

    return createPortal(
        <div ref={modalRef} className={['pf-fixed pf-z-[9999] pf-top-0 pf-right-0 pf-h-screen pf-w-screen pf-bg-secondary-dark0.6 pf-animate-offCanvasSlideLToR'].join(" ")}>
            <div className=' max-xl:pf-w-full pf-w-4/5 pf-h-full pf-bg-white pf-relative  pf-overflow-y-auto'>
                <div className='pf-right-0 pf-z-10 pf-text-end pf-p-8'>
                    <span onClick={handleSmoothCloseEvent} className='pf-bg-white pf-inline-flex pf-text-3xl pf-font-medium pf-rounded-lg pf-box-border pf-border-2 pf-border-secondary-dark pf-border-solid pf-min-w-[32px] pf-min-h-[32px] pf-w-max pf-h-max pf-cursor-pointer pf-text-secondary-dark hover:pf-bg-secondary hover:pf-border-secondary-dark0.6 pf-transition-all pf-duration-300 pf-ease-in'>
                        <i className="ph-fill ph-x-circle pf-w-full pf-h-full"></i>
                    </span>
                </div>
                <div className='pf-w-full max-xl:pf-px-3 pf-px-16 pf-py-8'>
                    {children}
                </div>
            </div>
        </div>
    , document.getElementById("portal"))
}
