import { useEffect, useState } from "react";

export default function useMouseCoordinates({ref}) {
    const [mouseCoordinates, setMouseCoordinates] = useState({ x: 0, y: 0 });
    const [isInsideRef, setIsInsideRef] = useState(false);

    useEffect(()=>{
        window.addEventListener('mousemove', handleMouseMove)
        return () => {
            window.removeEventListener('mousemove', handleMouseMove)
        }
        // eslint-disable-next-line
    }, [])

    const handleMouseMove = (event) => {
        const { clientX, clientY } = event;
        
        if (ref?.current) {
            const rect = ref.current.getBoundingClientRect();
            const isInsideRef = (
                clientX >= rect.left &&
                clientX <= rect.right &&
                clientY >= rect.top &&
                clientY <= rect.bottom
            );
            setIsInsideRef(isInsideRef);
        }
            
        setMouseCoordinates({ x: clientX, y: clientY });
    }

    return {'x':mouseCoordinates.x, 'y': mouseCoordinates.y, isInsideRef}
}
