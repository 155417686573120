function setCookieWithExpiry(name, value, hours=1) {
    const expires = new Date(Date.now() + hours * 60 * 60 * 1000);
    document.cookie = name + '=' + value + '; expires=' + expires.toUTCString() + '; path=/';
}

function getCookie(name) {
    const cookies = document.cookie.split(';');
    for (const cookie of cookies) {
      const parts = cookie.trim().split('=');
      if (parts[0] === name) {
        return parts[1];
      }
    }
    return null;
}

export {
    setCookieWithExpiry,
    getCookie
}