import {useState, useEffect, memo} from 'react'

export default memo(function Timer(props) {
    const { storedTimestamp=0, totalAllotedTimeInMs=0 } = props;
    const [timeRemaining, setTimeRemaining] = useState(null);
    const [hours, setHours] = useState(0);
    const [minutes, setMinutes] = useState(0);
    const [seconds, setSeconds] = useState(0);

    useEffect(() => {
        if (storedTimestamp) {
          const timeElapsed = Date.now() - storedTimestamp;
          const timeRemaining = totalAllotedTimeInMs - timeElapsed;
          setTimeRemaining(timeRemaining > 0 ? timeRemaining : 0);
        }
    }, [storedTimestamp, totalAllotedTimeInMs]);

    useEffect(() => {
        if (timeRemaining) {
            const intervalId = setInterval(() => {
                setTimeRemaining(timeRemaining - 1000);
            
                const hours = Math.floor(timeRemaining / (60 * 60 * 1000));
                const minutes = Math.floor((timeRemaining % (60 * 60 * 1000)) / (60 * 1000));
                const seconds = Math.floor((timeRemaining % (60 * 1000)) / 1000);
            
                setHours(hours);
                setMinutes(minutes);
                setSeconds(seconds);
            
                if (timeRemaining <= 0) {
                    clearInterval(intervalId);
                }
            }, 1000);
            return () => clearInterval(intervalId);
        }
    }, [timeRemaining]);
      

    return (
        <>
            {timeRemaining && <p>{`Available in ${hours}h ${minutes}m ${seconds}s`}</p>}
        </>
    )
})
