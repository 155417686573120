import {memo} from 'react'
import { info } from './contact.config'

export default memo(function BasicInfo() {

    const handleClick = (event,link) => {
        event.stopPropagation()
        window.open(link, '_blank', 'noreferrer');
    };

    return (
        <div className="pf-w-full pf-h-auto">
            <h2 className='pf-text-3xl pf-leading-4 pf-font-bold pf-mb-6 pf-text-secondary-dark pf-uppercase'>{info.heading}</h2>
            <p className='pf-text-base pf-leading-7 pf-font-normal pf-text-secondary-dark pf-mb-5'>{info.subHeading}</p>

            <ul className='pf-mb-7 pf-gap-2 pf-max-w-max'>
                {
                    info.formalSocialMethods && info.formalSocialMethods.map((_method,_idx)=>{
                        return (
                            <li key={`formal_social_methods_${_idx}`} className='pf-grid pf-grid-cols-7 pf-mb-5'>
                                <i className={[_method.icon,'pf-col-span-1 pf-text-4xl pf-text-primary'].join(' ')} />
                                <div className='pf-col-span-6 pf-ml-2'>
                                    <p className='pf-uppercase pf-text-secondary-dark pf-opacity-80 pf-font-semibold pf-leading-5'>{_method.key}</p>
                                    <p className=' pf-text-secondary-dark pf-font-bold pf-leading-5'>{_method.value}</p>
                                </div>
                            </li>
                        )
                    })
                }
            </ul>

            <ul className='pf-grid pf-grid-cols-12 pf-gap-2 max-xl:pf-gap-12 pf-max-w-max'>
                {
                    info.inFormalSocialMethods && info.inFormalSocialMethods.map((_method,_idx)=>{
                        return (
                            <li key={`informal_social_methods_${_idx}`} className='tooltip-btn xl:pf-col-span-2 pf-col-span-1 pf-text-2xl pf-p-2 pf-rounded-[50%] pf-leading-4 pf-z-10 pf-bg-secondary pf-text-secondary-dark pf-cursor-pointer pf-w-max' data-tooltip={_method.key} onClick={(e)=>handleClick(e, _method.value)}>
                                <i className={[_method.icon,' pf-col-span-3'].join(' ')} />
                            </li>
                        )
                    })
                }
            </ul>
        </div>
    )
})
