// import src from 'assets/image/work/src.jpg'
import desktop from 'assets/image/work/desktop.jpeg'
import queue from 'assets/image/work/queue.jpeg'
import sdk from 'assets/image/work/sdk.jpeg'
import security from 'assets/image/work/security.jpeg'
import lightning from 'assets/image/work/lego lightining.jpeg'
import rkdigitalstudio from 'assets/image/work/digital-studio.jpg'
import myportfolio from 'assets/image/work/my-portfolio.jpg'
import klodstorage from 'assets/image/work/lego-storage.avif'
import chatterbox from 'assets/image/work/chat.jpeg'

const workInfo = [
    {
        'name':'My Portfolio',
        'domain':'full stack',
        'src': myportfolio,
        'organization':'Freelance',
        'icon':'ph-fill ph-suitcase-simple',
        'description':{
            'text':"I meticulously constructed the entire <a href='https://ritesh.dabral.in/' target='_blank'>portfolio</a> website, overseeing <b>domain purchase and setup</b> with a focus on seamless user experience. Harnessing the power of React.js and S3, I ensured <b>efficient serverless traffic handling</b>, while implementing a robust <b>email service using AWS SQS and AWS Lambda for scalable operations</b>. The project boasts a <b>fully serverless architecture</b>, with <b>AWS API Gateway and Lambda enabling scalable APIs</b>. Rigorous <b>payload sanitization</b> safeguards user data, contributing to a secure environment. My portfolio takes center stage, presented with an <b>interactive UI and design that's fully responsive</b> across all devices, thanks to the precision of Tailwind CSS. <br/>Explore my work, engage with the responsive UI, and connect effortlessly through the contact page—all powered by scalable APIs, robust email integration, and the clean aesthetics of Tailwind CSS",
            'tech':["AWS Lambda", "AWS CloudWatch", "Node JS", "React JS", "Express", "HTML5", "AWS API Gateway", "AWS S3", "Tailwind CSS", "Domain Setup", "Hosting"]
        },
        'images':[],
        'link':'https://ritesh.dabral.in/'
    },
    {
        'name':'Scaled Queuing Architecture & Development',
        'domain':'backend',
        'src': queue,
        'organization':'Spyne.ai',
        'icon':'ph-fill ph-suitcase-simple',
        'description':{
            'text':"I engineered a scalable and resilient queuing architecture for Spyne leveraging <b>Node.js</b>, <b>SQS</b>, <b>CloudWatch</b>, <b>etcd</b>, <b>Redis</b>, <b>MySQL</b> and <b>MongoDB</b>. Employing a distributed leader-follower model, I achieved substantial performance gains by offloading tasks, enabling dynamic queue management, and minimizing database load. To safeguard against network partitions, I implemented a heartbeat mechanism and an automatic leader selection process that activates if the leader fails to respond within a specified timeframe. Additionally, I integrated cleanup and timeout procedures, coupled with load rebalancing, to guarantee equal priority for all queues without compromising the performance of AI servers.",
            'tech':["Node.js", "SQS", "CloudWatch", "etcd", "Redis", "MySQL", "MongoDB", "ALB", "nginx"]
        },
        'images':[],
        'link':''
    },
    {
        'name':'RK Digital Studio',
        'domain':'full stack',
        'src': rkdigitalstudio,
        'organization':'Freelance',
        'icon':'ph-fill ph-suitcase-simple',
        'description':{
            'text':"I orchestrated the entire website creation process, from <b>domain acquisition to setup</b>, utilizing React.js and AWS S3 for <b>seamless serverless traffic management</b>. Leveraging Node.js, Docker, and CloudWatch, I deployed backend services efficiently. The platform <b>showcases <a href='https://www.rkdigitalstudio.site/' target='_blank'>RK Digital Studio's</a> captivating images</b>, facilitated by a user-friendly <b>admin dashboard for easy image uploads</b>. A built-in contact collection page within the admin dashboard streamlines client communication. To optimize performance, I implemented image conversion for swift low-res loading, <b>ensuring the fastest client-side load times</b>. The interactive, mobile-responsive design, coupled with Docker for rapid deployments, marks a commitment to a seamless user experience.",
            'tech':["AWS EC2", "AWS CloudWatch", "Node JS", "React JS", "Mongo DB", "HTML5", "nginx", "Docker", "Modular CSS", "Domain Setup", "Hosting"]
        },
        'images':[],
        'link':'https://www.rkdigitalstudio.site/'
    },
    {
        'name':'Web SDK',
        'domain':'frontend',
        'src': sdk,
        'organization':'Spyne.ai',
        'icon':'ph-fill ph-suitcase-simple',
        'description':{
            'text':"Responsible for end-to-end development, testing, management and documentation of <a href='https://www.npmjs.com/package/spyne-pyxl' target='_blank'>Food SDK Spyne-Pyxl npm package</a> using <b>ReactJS</b> for the front end, <b>Node</b> and <b>Python</b> for APIs. Managed end-to-end processes, utilizing <b>Redis</b>, <b>MySQL</b>, and <b>MongoDB</b>. Implemented <b>PostHog</b> and <b>Sentry</b> for precise event capturing and error tracking. Leveraged <b>Metabase</b> for self-analytics, contributing to a 11% surge in new user signups. Integrated Kibana, Elasticsearch, and a Slack-based alert system for seamless API monitoring and real-time issue resolution. The tech stack, showcased by the web SDK processed <b>300000+ images within 3 months</b> of launch, ensures optimal performance and user experience. The web sdk is responsible for contributing to <b>44.77% of revenue</b> in food category. The same has been featured on multiple news websites and <a href='https://blog.swiggy.com/2023/11/03/the-power-of-pictures-elevate-your-menu-with-the-new-ai-fuelled-photoshoot-feature/' target='_blank'>blogs(Swiggy’s Blog)</a>.",
            'tech':["ReactJS", "NodeJS", "Python", "Elastic APM", "MySQL", "MongoDB", "NPM", "WEB-SDK", "Analytics", "Sentry", "Posthog"]
        }
    },
    {
        'name':`Scalable 'Permission Based Action Control' Architecture & Development`,
        'domain':'fullstack',
        'src': lightning,
        'organization':'Spyne.ai',
        'icon':'ph-fill ph-suitcase-simple',
        'description':{
            'text':"I led the design and execution of the complete schema and backend for an innovative <b>permission-based access control (PBAC) system</b> called <a href='https://console.spyne.ai' target='_blank'>console</a>, addressing a critical challenge for the company. This not only met the paramount need but also provided clients with refined control over access, tailored to their specific preferences. Eg. some clients favored direct image interactions, while others opted for task delegation to additional clients. Moreover, I seamlessly integrated <b>Elastic APM</b> to monitor API calls, implemented a system-level LRU cache for rapid data retrieval, and introduced a microservices architecture using Node.js. Leveraging <b>MySQL</b> for structured data and <b>MongoDB</b> for unstructured data, I fine-tuned data persistence for optimal reads and writes. To enhance the user experience, I orchestrated the implementation of <b>Next.js</b> for the frontend. An instrumental outcome of this effort is the migration of the entire Spyne dashboard and management to the console, harnessing the power of the PBAC system designed by me. At the core of Spyne's operations lies this critical service, handling the most intensive workloads with a <b>lightning-fast 37ms (avg)</b> and <b>305ms (p99)</b> response time since launch.",
            'tech':["NodeJS", "Mongo DB", "MySQL", "LRU Cache", "Redis", "SQS", "NextJS", "Elastic APM", "Sentry", "nginx", "Docker", "microservice architecture"]
        }
    },
    {
        'name':'Image Processing Chrome Extension',
        'domain':'frontend',
        'src': security,
        'organization':'Spyne.ai',
        'icon':'ph-fill ph-suitcase-simple',
        'description':{
            'text':"I developed a <b>Chrome extension</b> for the internal sales team that showcases the power of AI directly on client websites during online meetings. This <b>integration-free approach</b> allows for real-time demonstrations, engaging clients and accelerating lead generation. Built with <b>React</b>, <b>Node.js</b>, <b>MySQL</b>, <b>queueing</b>, polling, and a self-designed exponential front-off algorithm, the extension provides a personalized experience for each client. Its effectiveness resulted in a notable increase in lead conversion and revenue growth.",
            'tech':["ReactJS", "NodeJS", "MySQL", "Chrome Extension"]
        }
    },
    {
        'name':'Desktop Application',
        'domain':'fullstack',
        'src': desktop,
        'organization':'Spyne.ai',
        'icon':'ph-fill ph-suitcase-simple',
        'description':{
            'text':"Built a <b>desktop application</b> from the ground up using <b>Electron</b> and <b>React JS</b>, resolving quality check and editor challenges by establishing a centralized image management system. Achieved a significant leap in efficiency, processing <b>5000+ images</b> in a single operation—approximately <b>100 times performant</b> than the previous system. Implemented a robust queuing system in the backend using <b>Node.js</b> and <b>Python</b> for seamless API integration. Ensured system stability with <b>Sentry</b> for crash reporting and maintained a streamlined development pipeline through <b>CI/CD via circleCI</b> for efficient version releases. Notably, the application demonstrated <b>remarkably low memory consumption</b>, contributing to an enhanced user experience and overall performance. Lakhs of images being quality checked/edited via this application on a monthly basis.",
            'tech':["ReactJS", "Electron JS", "Node JS", "nginx", "microservice architecture", "Sentry", "Docker"]
        }
    },
    {
        'name':'Kloud Storage',
        'domain':'fullstack',
        'src': klodstorage,
        'organization':'freelance',
        'icon':'ph-fill ph-suitcase-simple',
        'description':{
            'text':"Crafting an <b>online storage solution</b> was an exhilarating venture, leveraging the dynamic capabilities of React.js for a polished frontend experience. This platform not only <b>facilitates seamless multipart uploads for handling sizable files</b> but also introduces versatile file sharing options, striking a balance between <b>public and private accessibility</b>. The integration of <b>Google OAuth</b> enhances user security and streamlines authentication. <b>Real-time storage visibility</b> ensures users stay informed instantly. On the backend, <b>Node.js in TypeScript</b> forms a robust foundation, coupled with <b>MongoDB</b> for efficient user and file database management. Witness the culmination of this effort at <a href='https://kloudstorage.netlify.app/' target='_blank'>Kloud-storage</a>, where innovation meets practicality in the realm of online storage.",
            'tech':["ReactJS", "Mongo DB", "Node JS", "heroku", "microservice architecture", "Typescript", "Object Oriented Programming", "Functional Programming"]
        },
        'images':[],
        'link':'https://github.com/Ritesh-Dabral/kloudstorage-BE'
    },
    {
        'name':'ChatterBox',
        'domain':'fullstack',
        'src': chatterbox,
        'organization':'freelance',
        'icon':'ph-fill ph-suitcase-simple',
        'description':{
            'text':"<a href='https://chatterbox-service.onrender.com/' target='_blank'>Chatterbox</a>, a cutting-edge real-time chat application powered by Node.js and Socket.io, seamlessly combines server-side rendering for efficient page loads with the dynamic responsiveness of web sockets. Offering both public and private chat functionalities, Chatterbox redefines the user experience by delivering instant communication in a sleek and engaging interface, making it the go-to platform for interactive and secure conversations.",
            'tech':["ReactJS", "Mongo DB", "Node JS", "heroku", "microservice architecture", "Typescript", "Object Oriented Programming", "Functional Programming"]
        },
        'images':['https://dabral-in-assets.s3.ap-south-1.amazonaws.com/ritesh/chatterbox/1.png','https://dabral-in-assets.s3.ap-south-1.amazonaws.com/ritesh/chatterbox/2.png', 'https://dabral-in-assets.s3.ap-south-1.amazonaws.com/ritesh/chatterbox/3.png', 'https://dabral-in-assets.s3.ap-south-1.amazonaws.com/ritesh/chatterbox/4.png']
    }
]

const DIRECTIONS = {
    TOP: "TOP",
    BOTTOM: "BOTTOM",
    LEFT: "LEFT",
    RIGHT: "RIGHT"
}

const animationOptions = {
    duration: 200,   
    forwards: true,
    easing: 'ease-out'
}

export {
    workInfo,
    DIRECTIONS,
    animationOptions
}