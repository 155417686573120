import {useCallback, useContext} from 'react'
import {useNavigate} from 'react-router-dom'
import XLDevicesNavbar from 'Components/Navbar/XLDevicesNavbar';
import NonXLDevicesNavbar from 'Components/Navbar/NonXLDevicesNavbar';
import GlobalContext from 'Context/GlobalContext';
import { items } from './navbar.config';

export default function Navbar() {

  const navigate = useNavigate();
  const {isXLDevice} = useContext(GlobalContext);

  const handlePageChange = useCallback((pathname, isActive)=>{
    if(isActive){
      return;
    }
    navigate(pathname);  
    // eslint-disable-next-line
  },[])

  
  return (
    <>
      {/* Nav bar for xl (min width: 1280px) devices */}
      {
        isXLDevice ?
        <XLDevicesNavbar handlePageChange={handlePageChange} items={items} currentPath={window.location.pathname} />
         :
        <NonXLDevicesNavbar handlePageChange={handlePageChange} items={items} currentPath={window.location.pathname}/>
      }
    </> 
  )
}