import Tick from './tick.json'
import CircularLoader from './loader.json';
import { useLottie } from "lottie-react";

export default function Lottie(props) {
    const {animationName="tick", loop=true} = props;
    const animations = {
        "tick": Tick,
        "loader": CircularLoader
    };
    const options = {
        "animationData": animations[animationName],
        "loop": loop
    };
    const { View } = useLottie(options);
    return <>{View}</>;
}
