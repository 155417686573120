import React from 'react'

const Main = (props)=> {
  return (
    <div className={["pf-box-border pf-h-full pf-w-full pf-m-auto max-xl:pf-max-w-[100%] pf-max-w-[85%] pf-py-0 max-xl:pf-px-5 pf-px-[30px]", props.className].join(' ')}>
      {props.children}
    </div>
  )
}

const Container = {
  'Container': Main
}

export default Container;