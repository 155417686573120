import {memo} from 'react'
import profilePic from 'assets/image/ProfileCompressed.png'
import { homeConfig } from 'Components/Home/home.config'
import Button from 'AtomicComponents/Button/Button'
import {useNavigate} from 'react-router-dom'

export default memo(function NonXLDevicesHome() {
    const navigate = useNavigate()
    const handleRedirection = () => {
        navigate('/about-me')
    }

    return (
        <div className='xl:pf-hidden pf-animate-fadeIn pf-w-screen pf-h-screen'>
            <div className="pf-w-4/5 pf-pt-5 pf-border pf-border-solid pf-border-secondary-light pf-mt-20 pf-mx-auto pf-mb-6">
                <img className="pf-w-72 pf-h-72 pf-object-cover pf-rounded-[50%] pf-mt-0 pf-mx-auto pf-mb-6" src={profilePic} alt="profile" />
            </div>

            {/* Right */}
            <div className='pf-col-span-7'>
                <div className='pf-w-full pf-h-full pf-flex pf-flex-col pf-justify-center pf-items-center pf-text-center pf-py-8 pf-break-words pf-px-2 md:pf-px-28 pf-mt-0 pf-mx-auto pf-mb-6'>
                    <div className='pf-w-full pf-text-4xl pf-font-bold'>
                        <h1 className="pf-uppercase md:pf-ml-8 pf-text-primary">
                            {homeConfig.name}
                            <span className="pf-uppercase pf-block pf-text-secondary-dark pf-mt-2 ">{homeConfig.subHeadings[0]} <br/>{homeConfig.subHeadings[1]}</span>
                        </h1>
                    </div>


                    <p className='pf-my-8 pf-text-base pf-leading-9 pf-text-secondary-dark '>
                    {homeConfig.description}
                    </p>


                    <Button.Button onClick={handleRedirection}>
                        <Button.Item className="capsuleButton">
                            <Button.Text className="pf-col-span-9 pf-uppercase pf-inline-flex pf-justify-center pf-items-center pf-font-semibold" text={homeConfig.cta} />
                            <Button.Item className="pf-col-span-3 pf-inline-block pf-bg-primary pf-text-white pf-text-2xl pf-border-solid pf-p-4 pf-rounded-[50%] pf-leading-4">
                                <Button.Icon icon="ph-fill ph-arrow-right"/>
                            </Button.Item>
                        </Button.Item>
                    </Button.Button>
                </div>
            </div>
        </div>
    )
})
