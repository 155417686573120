import React, { useContext } from 'react'
import GlobalContext from 'Context/GlobalContext'
import XLDevicesHome from 'Components/Home/XLDevicesHome'
import NonXLDevicesHome from 'Components/Home/NonXLDevicesHome'

export default function Home() {

    const {isXLDevice} = useContext(GlobalContext)
    return (
        <>
            {
                isXLDevice ?
                <XLDevicesHome />
                :
                <NonXLDevicesHome />
            }

            <div className='topDownPageRenderTransisition'></div>
        </>
    )
}
