import { workInfo } from './work.config'
import WorkCard from './WorkCard';

export default function Work() {
    return (
        <>
            <ul className='pf-grid pf-grid-cols-12 pf-gap-1 pf-h-max'>
                {
                    workInfo.map((work, index) => {
                        return <li 
                            key={`works_port_${index}`} 
                            className="pf-col-span-12 md:pf-col-span-6 xl:pf-col-span-4 pf-flex pf-justify-center pf-items-center pf-h-max pf-p-4 pf-overflow-hidden"
                        >
                            <WorkCard work={work}/>
                        </li>
                    })
                }
            </ul>
        </>
    )
}
