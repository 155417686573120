import Container from 'AtomicComponents/Container/Container'
import Heading from 'AtomicComponents/Heading/Heading'
import Work from './Work'

export default function Portfolio() {
    return (
       <>
            <div className='pf-animate-fadeIn pf-w-full pf-h-full max-xl:pf-overflow-x-hidden'>
                <Container.Container>
                    <div className="pf-relative pf-top-20 md:pf-top-24 pf-z-0 pf-pb-24">
                        <Heading.Heading subHeading="works" heading={["My", "portfolio"]} className="pf-pb-[70px]"/>

                        {/* work */}
                        <Work />
                    </div>
                </Container.Container>
            </div>

            {/* top down animate */}
            <div className='topDownPageRenderTransisition'></div>
       </>
    )
}
