const personalInfo = [
    [
        {key:'First Name',value:"Ritesh"},
        {key:'Last Name',value:"Dabral"},
        {key:'Age',value:"26 Years"},
        {key:'Nationality',value:"Indian"},
    ],
    [
        {key:'Address',value:"India"},
        {key:'Email',value:"riteshdabral1@gmail.com"},
        {key:'Langages',value:"Hindi, English"},
        {key:'Phone',value:"+91-9911536608"}
    ]
]

const stats = [
    [
        {'key':'3+', 'value':'years of experience'},
        {'key':'300K+', 'value':'new lines contributed'},
    ],
    [
        {'key':'3', 'value':'Accolades earned'},
        {'key':'35+', 'value':'project contribution'}
    ]
]


const skills = [
    {
        'topic': 'programming languages',
        'icon':'ph-fill ph-code',
        'details': [
            {
                'skill': 'javascript',
                'level': 5
            },
            {
                'skill': 'c++',
                'level': 3
            },
            {
                'skill': 'python',
                'level': 1
            }
        ]
    },
    {
        'topic': 'Backend Technologies',
        'icon': 'ph-fill ph-hard-drives',
        'details': [
            {
                'skill': 'node js',
                'level': 4
            },
            {
                'skill': 'express',
                'level': 4
            },
            {
                'skill': 'sails',
                'level': 4
            }
        ]
    },
    {
        'topic': 'frontend Technologies',
        'icon': 'ph-fill ph-file-html',
        'details': [
            {
                'skill': 'react js',
                'level': 5
            },
            {
                'skill': 'next js',
                'level': 3
            },
            {
                'skill': 'electron js',
                'level': 4
            },
            {
                'skill': 'tailwind css',
                'level': 4
            },
            {
                'skill': 'bootstrap css',
                'level': 4
            },
            {
                'skill': 'html5',
                'level': 4
            },
            {
                'skill': 'css3',
                'level': 4
            }
        ]
    },
    {
        'topic': 'database',
        'icon':'ph-fill ph-database',
        'details': [
            {
                'skill': 'mysql',
                'level': 4
            },
            {
                'skill': 'mongo db',
                'level': 3
            },
            {
                'skill': 'kafka',
                'level': 1
            },
            {
                'skill': 'redis',
                'level': 2
            },
            {
                'skill': 'etcd',
                'level': 3
            }
        ]
    },
    {
        'topic': 'Code Versioning',
        'icon': 'ph-fill ph-git-branch',
        'details': [
            {
                'skill': 'git',
                'level': 5
            },
            {
                'skill': 'gitlab',
                'level': 4
            },
            {
                'skill': 'github',
                'level': 3
            }
        ]
    },
    {
        'topic': 'Software Development Practices',
        'icon': 'ph-fill ph-users-three',
        'details': [
            {
                'skill': 'scrum',
                'level': 5
            },
            {
                'skill': 'jira',
                'level': 5
            }
        ]
    },
    {
        'topic': 'Monitoring & Error Tracking',
        'icon': 'ph-fill ph-bug',
        'details': [
            {
                'skill': 'New Relic',
                'level': 5
            },
            {
                'skill': 'Uptime robot',
                'level': 5
            },
            {
                'skill': 'Sentry',
                'level': 5
            },
            {
                'skill': 'Elastic APM',
                'level': 5
            }
        ]
    },
    {
        'topic': 'Cloud Services',
        'icon': 'ph-fill ph-cloud',
        'details': [
            {
                'skill': 'Google Cloud Platform (GCP)',
                'level': 5
            },
            {
                'skill': 'Amazon web services (AWS)',
                'level': 5
            },
        ]
    },
    {
        'topic': 'Other',
        'icon': 'ph-fill ph-circles-three-plus',
        'details': [
            {
                'skill': 'CI/CD',
                'level': 3
            },
            {
                'skill': 'NPM',
                'level': 4
            },
            {
                'skill': 'Docker',
                'level': 4
            },
            {
                'skill': 'OOP',
                'level': 5
            },
            {
                'skill': 'Website Hosting',
                'level': 5
            }
        ]
    }
]

const education = [
    {
        'course':'BACHELOR DEGREE',
        'institution':'GGSIPU',
        'stream':'B.Tech in Computer Science Engineering',
        'description':'',
        'duration':'2016 - 2020',
        'icon':'ph-fill ph-graduation-cap'
    },
    {
        'course':'higher education',
        'institution':'CRPF Public School',
        'stream': 'science',
        'description':'',
        'duration':'2014 - 2015',
        'icon':'ph-fill ph-graduation-cap'
    },
    {
        'course':'Secondary Education',
        'institution':'CRPF Public School',
        'stream': 'general',
        'description':'',
        'duration':'2013 - 2014',
        'icon':'ph-fill ph-graduation-cap'
    }
]

const experience = [
    {
        'position':'Tech Lead',
        'profile': 'Full stack developer',
        'organization':'Spyne.ai',
        'description':[
            {
                'text':"🚀 As the leader of a small but mighty team, I'm the go-to person for coding magic and making things better. I not only write code but also <b>focus on making our systems stronger and safer</b>. In our team, <b>we work in short bursts, or sprints</b>, where I make sure everyone is working together smoothly. I'm the one in charge, steering the ship and keeping things on track. I also <b>manage a team of four amazing individuals</b>, guiding them through the coding journey. My day involves checking and improving our code, fixing any issues, and keeping a close eye on how everything is running. I'm all about solving problems, not just in the code but also understanding what our clients need and making it happen. It's not just about meeting expectations; it's about going above and beyond to make our clients happy and our software top-notch.",
                'tech':[]
            },
            {
                'text': "🛠️ I orchestrated a symphony of technologies—Node.js, SQS, CloudWatch, etcd, Redis, MySQL, and MongoDB—to craft a <b>queuing architecture for Spyne</b> that scales to the heavens and stands resilient against any storm. My <b>distributed leader-follower model</b> unleashed performance gains like lightning, skillfully offloading tasks, <b>dynamically managing queues</b>, and <b>easing the burden on databases</b>. To thwart network partitions, I wove a <b>heartbeat into the system</b>'s very fabric, ensuring a new leader would rise should the current one falter. To maintain harmony, I <b>integrated cleanup and timeout procedures</b>, expertly balancing loads to grant <b>equal priority to all queues without sacrificing the performance of our AI servers</b>. This architecture stands as a testament to my passion for performance and resilience, a bedrock upon which Spyne can soar to new heights.",
                'tech':[]
            },
            {
                'text':`🚦 I transformed our public image processing APIs into impenetrable fortresses, weaving a web of security measures that thwarted scammers and safeguarded trust. I wove invisible reCAPTCHA trust tokens to <b>grant access only through our app</b>, strategically throttled key processing requests to <b>repel DoS attacks</b>, and cloaked code in a veil of <b>obfuscation</b>, rendering dev tools powerless. The result? A dramatic <b>decline in scam processing</b>, a soaring sense of security, and APIs that stand as testaments to resilience`,
                'tech':[]
            },
            {
                'text':"👩‍💻 Pioneered a <b>web SDK for flawless image processing</b>, handling thousands of images flawlessly. Built the complete stack from front-end (React) to back-end (Node, Python) with Redis, SQL, MongoDB, and PostHog, boosting user engagement. Real-time monitoring with Kibana, Elasticsearch, and Slack ensured optimal performance and user experience. <b>Featured on multiple news websites and blogs</b>, this innovative SDK has transformed image processing and user experience.",
                'tech':[]
            },
            {
                'text':"🔄 I streamlined real-time image processing, <b>slashing wait times by 90%</b>! ✂️ Reduced API response time <b>from 30-45 seconds per image to a mere 3-4 seconds per batch</b>, ensuring seamless backfit to existing logic. Furthermore, I also <b>prioritized UX</b> by implementing frontend changes that eliminated the perception of slow loading, fostering a smooth and efficient user experience. This optimization significantly improved efficiency, user experience, and overall system performance.",
                'tech':[]
            },
        ],
        'duration':'2023 - Present',
        'icon':'ph-fill ph-suitcase-simple'
    },
    {
        'position':'SDE 1',
        'profile': 'Full stack developer',
        'organization':'Spyne.ai',
        'description':[
            {
                'text':"💯 I thrived in a multifaceted role, spearheading performance enhancements. I tackled <b>code modifications</b>, <b>bug fixes</b>, and <b>code reviews</b>, while <b>mentoring colleagues</b> and fostering growth. My commitment to learning fueled <b>API restructuring</b> and <b>SQL/No-SQL query optimization</b>, contributing to a significantly enhanced system. Additionally, I successfully managed service deployments, <b>built robust pipelines</b>, and cultivated <b>strong client connections</b>. My dedication and diverse skillset proved instrumental in driving overall performance and fostering a collaborative, high-performing environment.",
                'tech':[]
            },
            {
                'text':"👤 Designed and built a cutting-edge dynamic <b>PBAC (Permission Based Access Control) architecture and service</b> offering clients granular access control. Integrated Elastic APM, LRU cache, and Node.js microservices for performance. Optimized data storage with MySQL and MongoDB. Successful migration of the Spyne dashboard and management to the new PBAC system proves its effectiveness.",
                'tech':[]
            },
            {
                'text': "🧩 I <b>transformed online sales meetings into AI-powered showcases</b>, seamlessly integrated into client websites with a game-changing <b>Google Chrome extension</b>. No integrations, no delays—just real-time magic that engages clients and drives leads home. My secret? An <b>exponential backoff strategy for silky-smooth backend polling</b>, plus ingenious logic to <b>conquer lazy-loaded websites</b>. This extension delivers personalized experiences that have already ignited revenue growth.",
                'tech':[]
            },
            {
                'text':"🖥️ Built an Electron/React <b>desktop app for centralized image management, transforming quality check and editor workflows</b>. Boosted <b>efficiency by 100x</b>, capable of <b>processing 5000+ images per operation</b>. Implemented robust Node.js/Python queuing for seamless API integration. Utilized Sentry for crash reporting and maintained a streamlined <b>CI/CD pipeline</b> through CircleCI. Notably achieved low memory consumption for an enhanced user experience. Lakhs of images are processed or quality-checked monthly.",
                'tech':[]
            },
            {
                'text':"⚡ <b>Slashed costs</b> by deploying <b>self-hosted Sentry</b>, empowering aggressive bug/crash detection and faster releases.",
                'tech':[]
            },
            {
                'text':"🚀 Developed and launched an <b>image tagging feature (hotspots) with a 360-view capability</b>! Allowing customers to mark key points on images, and view them across multiple devices. Boosting engagement to new heights!",
                'tech':[]
            }
        ],
        'duration':'2021 - 2023',
        'icon':'ph-fill ph-suitcase-simple'
    },
    {
        'position':'ASE',
        'profile': 'Java developer',
        'organization':'Accenture Pvt Ltd',
        'description':[
            {
                'text':"Responsible for coding in <b>JAVA</b>, testing, deploying the application (OPUS) on development servers and verifying the bundling as well as my individual changes.",
                'tech':[]
            }
        ],
        'duration':'2021 - 2021',
        'icon':'ph-fill ph-suitcase-simple'
    },
]

const cvDetails = {
    'text': 'Download CV',
    'link': 'https://drive.google.com/file/d/1wURYWgKRAThxYDabNZ7oNM5CA_ADQ2X9/view'
}

export {
    personalInfo,
    stats,
    education,
    experience,
    skills,
    cvDetails
}