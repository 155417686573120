import Button from 'AtomicComponents/Button/Button'
import {memo, useState} from 'react'
import { BUTTON_NOT_READY_TILL_HRS, CONTACT_SUBMITTED_COOKIE, CONTACT_SUBMITTED_TIME_COOKIE, inputKeys } from './contact.config'
import Lottie from 'AtomicComponents/Lottie/Lottie'
import { getCookie, setCookieWithExpiry } from 'Utils/functions'
import Timer from './Timer'

export default memo(function Mailer() {

    const [mailerInfo, setMailerInfo] = useState({
        [inputKeys.name]:'',
        [inputKeys.email]:'',
        [inputKeys.subject]:'',
        [inputKeys.message]:''
    })

    const [errorInfo, setErrorInfo] = useState({
        [inputKeys.name]:'',
        [inputKeys.email]:'',
        [inputKeys.subject]:'',
        [inputKeys.message]:''
    })
    
    const [isSubmitted, setIsSubmitted] = useState(()=>{
        const cookieExist = getCookie(CONTACT_SUBMITTED_COOKIE);
        if(cookieExist){
            return true;
        }
        return false;
    })

    const [storedTimestamp, setStoredTimestamp] = useState(()=>{
        const cookieExist = getCookie(CONTACT_SUBMITTED_TIME_COOKIE);
        if(cookieExist){
            return Number(cookieExist);
        }
        return 0;
    })
    
    const [disableSubmit, setDisableSubmit] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    function validateInput({email, name, subject, message}) {
        const errors = {};
    
        const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
      
        if (!emailRegex.test(email)) {
          errors.email = 'Valid email is required';
        }
        if ((!name || (name && !name.trim()) || (name && name.trim().length>50))) {
          errors.name = 'Name must be non empty and upto 50 characters';
        }
        if ((!subject || (subject && !subject.trim()) || (subject && subject.trim().length>100))) {
            errors.subject = 'Subject must be non empty and upto 100 characters';
        }
        if ((!message || (message && !message.trim()) || (message && message.trim().length>100))) {
            errors.message = 'Message must be non empty and upto 2000 characters';
        }
        
        return errors;
    }

    const handleOnChange = (event)=>{
        setMailerInfo(prev=>{
            return {
                ...prev,
                [event.target.name] : event.target.value
            }
        })
        setErrorInfo(prev=>{
            return {
                ...prev,
                [event.target.name] : ""
            }
        })
    }

    const handleOnBlur = (event)=>{
        const value = event.target.value.trim();
        const error = validateInput({
            email:mailerInfo[inputKeys.email],
            name:mailerInfo[inputKeys.name],
            subject:mailerInfo[inputKeys.subject],
            message:mailerInfo[inputKeys.message]
        })

        if(Object.keys(error).length){
            setErrorInfo(error)
            setDisableSubmit(true)
        }else{
            setDisableSubmit(false)
        }
        setMailerInfo(prev=>{
            return {
                ...prev,
                [event.target.name] : value
            }
        })
    }

    const handleSubmit = async(event)=>{
        event.stopPropagation();
        setIsLoading(true);
        try {
            const error = validateInput({
                email:mailerInfo[inputKeys.email],
                name:mailerInfo[inputKeys.name],
                subject:mailerInfo[inputKeys.subject],
                message:mailerInfo[inputKeys.message]
            })
    
            if(Object.keys(error).length>0){
                setErrorInfo(error)
                setDisableSubmit(true);
                return;
            }

            const myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

            const urlencoded = new URLSearchParams();
            urlencoded.append("name", mailerInfo[inputKeys.name]);
            urlencoded.append("email", mailerInfo[inputKeys.email]);
            urlencoded.append("subject", mailerInfo[inputKeys.subject]);
            urlencoded.append("message", mailerInfo[inputKeys.message]);

            const requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: urlencoded,
                redirect: 'follow',
                mode: 'no-cors'
            };

            await fetch(`${process.env.REACT_APP_BACKEND_DOMAIN}/v1/send-message`, requestOptions);
            setMailerInfo((prev)=>{
                Object.keys(prev).forEach((key)=>{
                    prev[key] = "";
                })
                return {...prev}
            })
            setIsSubmitted(true);
            setCookieWithExpiry(CONTACT_SUBMITTED_COOKIE, "true", BUTTON_NOT_READY_TILL_HRS);
            setCookieWithExpiry(CONTACT_SUBMITTED_TIME_COOKIE, (Date.now())+"", BUTTON_NOT_READY_TILL_HRS);
            setStoredTimestamp(Date.now()); // start timer
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    }

    return (
        <>
            {
                isSubmitted  &&
                <div className='pf-w-full pf-h-full pf-flex pf-flex-col pf-justify-center pf-items-center'>
                    <div className='pf-w-56 pf-h-56'>
                        <Lottie/>
                    </div>
                    <p className="pf-text-base pf-leading-7 pf-font-normal pf-text-secondary-dark pf-mb-5">You can submit another form after {BUTTON_NOT_READY_TILL_HRS} hrs from the last submission time</p>
                    <span className='pf-text-base pf-leading-7 pf-font-normal pf-text-secondary-dark pf-mb-5'>
                        <Timer 
                            storedTimestamp={storedTimestamp} 
                            totalAllotedTimeInMs={BUTTON_NOT_READY_TILL_HRS * 60 * 60 * 1000} 
                        />
                    </span>
                </div>
            }
            {
                isLoading && 
                <div className='pf-w-full pf-h-full pf-flex pf-flex-col pf-justify-center pf-items-center'>
                    <div className='pf-w-56 pf-h-56'>
                        <Lottie animationName="loader"/>
                    </div>
                    <p className="pf-text-base pf-leading-7 pf-font-normal pf-text-secondary-dark pf-mb-5">Submitting your message. Sit back and relax!</p>
                </div>
            }
            {
                (!isLoading && !isSubmitted) && 
                <div className="pf-w-full pf-h-full">
                    <div className='xl:pf-grid xl:pf-grid-cols-12 xl:pf-gap-2 pf-mb-3'>
                        <input placeholder={`Your ${inputKeys.name} *`} type='text' className={['xl:pf-col-span-4 max-xl:pf-w-full max-xl:pf-my-3', (errorInfo[inputKeys.name] && "error-state")].join(" ")} name={inputKeys.name} value={mailerInfo[inputKeys.name]} onChange={handleOnChange} onBlur={handleOnBlur}/>
                        <input placeholder={`Your ${inputKeys.email} *`} type='email' className={['xl:pf-col-span-4 max-xl:pf-w-full max-xl:pf-my-3', (errorInfo[inputKeys.email] && "error-state")].join(" ")} name={inputKeys.email} value={mailerInfo[inputKeys.email]} onChange={handleOnChange} onBlur={handleOnBlur}/>
                        <input placeholder={`Your ${inputKeys.subject} *`} type='text' className={['xl:pf-col-span-4 max-xl:pf-w-full max-xl:pf-my-3', (errorInfo[inputKeys.subject] && "error-state")].join(" ")} name={inputKeys.subject} value={mailerInfo[inputKeys.subject]} onChange={handleOnChange} onBlur={handleOnBlur}/>
                    </div>
        
                    <div className='pf-mb-3'>
                        <textarea placeholder={`Your ${inputKeys.message} *`} className={['pf-w-full pf-max-h-[200px] pf-min-h-[200px]', (errorInfo[inputKeys.message] && "error-state")].join(" ")} name={inputKeys.message} value={mailerInfo[inputKeys.message]} onChange={handleOnChange} onBlur={handleOnBlur}></textarea>
                    </div>
                                
                    <Button.Button disabled={disableSubmit} onClick={handleSubmit}>
                        <Button.Item className="capsuleButton">
                            <Button.Text className="pf-col-span-9 pf-uppercase pf-inline-flex pf-justify-center pf-items-center pf-font-semibold" text={"send message"} />
                            <Button.Item className="pf-col-span-3 pf-inline-block pf-bg-primary pf-text-white pf-text-2xl pf-border-solid pf-p-4 pf-rounded-[50%] pf-leading-4">
                                <Button.Icon icon="ph-fill ph-arrow-right"/>
                            </Button.Item>
                        </Button.Item>
                    </Button.Button>          
                </div>
            }
        </>
    )
})
