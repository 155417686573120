import {useCallback, useState} from 'react'
import { experience, education } from './about.config'
import Modal from 'AtomicComponents/Modal/Modal';

export default function ExperienceAndEducation() {

    const [activeAccordianIdx, setActiveAccordianIdx] = useState(-1);
    
    const handleAccordianClick = useCallback((e,idx) => {
        if(e){
            e.stopPropagation();
        }
        if(activeAccordianIdx === idx) {
            setActiveAccordianIdx(-1);
        } else {
            setActiveAccordianIdx(idx);
        }
    },[activeAccordianIdx])
    
    return (
        <div className='pf-w-full pf-h-max pf-grid pf-grid-cols-12 pf-gap-3'>
            <p className='pf-uppercase pf-col-span-12 pf-text-[26px] pf-font-bold pf-text-secondary-dark pf-text-center pf-pb-6'>My Experience & education</p>
            {/* EXP */}
            <ul className='pf-grid max-xl:pf-col-span-12 pf-col-span-6 pf-gap-1'>
                {
                    experience.map((info, index) => {
                        return <li className="aboutExpNdEduListContainer" key={`experience_${index}`}>
                            {
                                info.icon && <i className={[info.icon, "aboutExpNdEduListIcon"].join(' ')} />
                            }
                            <span className='pill !pf-text-xs'>{info.duration}</span>
                            <p className=' pf-text-lg pf-my-3 pf-uppercase'>
                                <span className='pf-text-secondary-dark pf-font-bold'>{info.profile} &nbsp;-&nbsp; </span>
                                <span className='pf-text-secondary-dark pf-opacity-80 pf-font-semibold pf-text-base'>{info.organization}</span>
                            </p>
                            <p className=' pf-text-sm pf-my-3 pf-uppercase pf-text-secondary-dark pf-opacity-70 pf-inline-flex  pf-justify-between pf-w-full'>
                                {info.position}

                                <span className={["pf-text-primary pf-cursor-pointer pf-mx-1 pf-font-bold"].join(" ")} onClick={e=>handleAccordianClick(e, index)}>
                                    More
                                    <i className="ph-fill ph-caret-right"></i>
                                </span>
                            </p>
                        </li>
                    })
                }
            </ul>

            {/* EDU */}
            <ul className='pf-grid max-xl:pf-col-span-12 pf-col-span-6 pf-gap-1'>
                {
                    education.map((info, index) => {
                        return <li className="aboutExpNdEduListContainer" key={`education_${index}`}>
                            {
                                info.icon && <i className={[info.icon, "aboutExpNdEduListIcon"].join(' ')} />
                            }
                            <span className='pill !pf-text-xs'>{info.duration}</span>
                            <p className=' pf-text-lg pf-my-3 pf-uppercase'>
                                <span className='pf-text-secondary-dark pf-font-bold'>{info.course} &nbsp;-&nbsp; </span>
                                <span className='pf-text-secondary-dark pf-opacity-80 pf-font-semibold pf-text-base'>{info.institution}</span>
                            </p>
                            <p className=' pf-text-sm pf-my-3 pf-uppercase pf-text-secondary-dark pf-opacity-70'>
                                {info.stream}
                            </p>
                        </li>
                    })
                }
            </ul>


            {/* More info modal */}
            {
                (activeAccordianIdx >= 0) &&
                <Modal handleClose={(e)=>handleAccordianClick(e, -1)}>
                    <div className="pf-relative pf-py-0 pf-ps-[60px] pf-pe-5 pf-box-border pf-mb-[30px] after:pf-content-[''] after:pf-border-[1px] after:pf-border-solid after:pf-border-secondary-dark after:pf-border-spacing-60 after:pf-h-full after:pf-absolute after:pf-top-[10px] after:pf-left-[1.2rem] after:pf-opacity-20" key={`experience_${activeAccordianIdx}`}>
                        {
                            experience[activeAccordianIdx].icon && <i className={[experience[activeAccordianIdx].icon, "pf-absolute pf-top-0 pf-left-0 pf-z-[1] pf-border pf-border-solid pf-border-primary pf-rounded-[50%] pf-p-1 pf-bg-primary pf-text-white pf-inline-flex pf-justify-center pf-items-center pf-w-10 pf-h-10 pf-text-xl"].join(' ')} />
                        }
                        <span className='pill'>{experience[activeAccordianIdx].duration}</span>
                        <p className=' pf-text-lg pf-my-3 pf-uppercase'>
                            <span className='pf-text-secondary-dark pf-font-bold'>{experience[activeAccordianIdx].profile} &nbsp;-&nbsp; </span>
                            <span className='pf-text-secondary-dark pf-opacity-80 pf-font-semibold pf-text-xl'>{experience[activeAccordianIdx].organization}</span>
                        </p>
                        <p className=' pf-text-lg pf-mt-3 pf-uppercase pf-text-secondary-dark pf-opacity-70 pf-inline-flex  pf-justify-between pf-w-full'>
                            {experience[activeAccordianIdx].position}
                        </p>
                        {
                            <ul className={["pf-transition-all pf-duration-500 pf-ease-in-out"].join(" ")}>
                                {
                                experience[activeAccordianIdx].description.map((_description, _cdx)=>{
                                    return <li key={`description_${_cdx}_${activeAccordianIdx}`} className="pf-py-0 max-xl:pf-ps-0 max-xl:pf-pe-0 xl:pf-ps-8 xl:pf-pe-4 pf-my-4">
                                        <p className='beforeDash' dangerouslySetInnerHTML={{__html: _description.text}} />
                                        <p className='pf-break-words pf-mt-2'>
                                            {
                                                _description.tech.map((_tech, _tdx)=>{
                                                    return <span key={`tech_${_tdx}_${_cdx}_${activeAccordianIdx}`} className="pill !pf-text-sm pf-inline-flex">{_tech}</span>
                                                
                                                })
                                            }
                                        </p>
                                    </li>
                                })
                                } 
                            </ul>
                        }
                    </div>
                </Modal>
            }
        </div>
    )
}
