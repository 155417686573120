import Nav from 'AtomicComponents/Navbar/Navbar';
import {Fragment, useState, memo} from 'react'

export default memo(function NonXLDevicesNavbar(props) {

    const {items, handlePageChange, currentPath} = props;

    const [showOffcanvas, setShowOffcanvas] = useState(false);

    const handleOffCanvasVisibility = (show=false)=>{
        setShowOffcanvas(show);
    }

    const changePage = ({redirect, isActive})=>{
        handlePageChange(redirect,isActive)
        handleOffCanvasVisibility(false); // close the offcanvas
    }

    return (
        <Nav.Navbar className={["pf-fixed pf-z-10 " , (showOffcanvas ? " pf-animate-offCanvasSlideLToR  pf-h-full pf-w-full pf-top-0 pf-left-0 pf-z-[110] pf-bg-secondary" : "pf-h-max pf-w-fit pf-right-0 pf-top-2")].join(' ')}>
            <Nav.Item onClick={()=>handleOffCanvasVisibility(!showOffcanvas)} className="pf-fixed pf-right-0 pf-top-4 pf-p-3 pf-mr-5 pf-bg-secondary pf-text-2xl pf-text-center pf-font-medium pf-rounded-lg pf-box-border pf-w-14 pf-h-14 pf-inline-flex pf-justify-center pf-items-center">
                <Nav.Icon icon={showOffcanvas ? "ph ph-x" :  "ph ph-list"}/>
            </Nav.Item>

            {
                showOffcanvas && <Nav.Item className="pf-box-border pf-fixed pf-p-3 pf-top-20 pf-w-full pf-h-max pf-ps-8">
                {
                    items.map((_item, index)=>{
                        const isActive = currentPath===_item.redirect;
                        const activeClassName = isActive ? 'pf-text-primary' : 'pf-text-secondary-dark';
                        return <Fragment key={`nav_items_${index}`}>
                            <div 
                                onClick={()=>changePage({'redirect':_item.redirect, 'isActive': isActive})} 
                                className='pf-w-full pf-h-auto pf-left-8'
                            >
                                <Nav.Item className={["pf-grid pf-grid-cols-12 pf-text-medium md:pf-text-xl pf-py-4", activeClassName].join(' ')}>
                                    <Nav.Item className="pf-col-span-1">
                                        <Nav.Icon icon={_item.icon} />
                                    </Nav.Item>
                                    <Nav.Text className="pf-uppercase pf-col-span-11" text={_item.text} />
                                </Nav.Item>
                                {
                                    (index<items.length-1) && <Nav.Item className='pf-border-b pf-border-solid pf-border-secondary-light'/>
                                }
                            </div>
                        </Fragment>
                    })
                }
                </Nav.Item>
            }
        </Nav.Navbar> 
    )
})
