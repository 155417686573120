import Navbar from 'Components/Navbar/Navbar';
import Routes from 'Routes/Routes';
import GlobalContext from 'Context/GlobalContext';
import { useEffect, useState, useRef } from 'react';

function App() {

  const [isXLDevice, setIsXLDevice] = useState(false);
  const containerRef = useRef(null); // Reference to the container element

  useEffect(() => {
    const observer = new ResizeObserver((entries) => {
      setIsXLDevice(entries[0].contentRect.width >= 1280);
    });

    // Observe the container element
    observer.observe(containerRef.current);

    return () => observer.disconnect();
  }, []);

  return (
    <div className="pf-w-screen pf-h-screen pf-overflow-auto" ref={containerRef}>
      <GlobalContext.Provider value={{isXLDevice}}>
        <Navbar />
        <Routes />
      </GlobalContext.Provider>
    </div>
  );
}

export default App;
